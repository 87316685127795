import './App.css';
import './styles/Hover.css';
import React, {useState, useRef, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';

// Screens
import LandingPageV2 from './screens/LandingPageV2.js';
import PrivacyPolicy from './screens/PrivacyPolicy.js';
import ExperienceListingDetails from './screens/ExperienceListingDetails.js';
import StayListingDetails from './screens/StayListingDetails.js';
import GuidePage from './screens/GuidePage.js';
import AIGuidePage from './screens/AIGuidePage.js';
import AboutPage from './screens/AboutPage.js';
import CallTranscriptSubmit from './screens/CallTranscriptSubmit.js';
import GuideRequestPage from './screens/GuideRequestPage.js';
import ManageAccountScreen from './screens/ManageAccountScreen.js';

import {Fade, Reveal} from "react-awesome-reveal";
import * as amplitude from '@amplitude/analytics-browser';
import ScrollToTop from "./ScrollToTop.js";
import {useNavigate,useLocation} from 'react-router-dom';
import Icons from './utils/Icons.js';
import {IconContext} from "react-icons";
import {useMediaQuery} from 'react-responsive';
import {TailSpin} from 'react-loader-spinner';

import axios from "axios";

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  addDoc,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { getUTMQueryParams, capFirst } from './utils/UtilFunctions.js';
import moment from 'moment';
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const app = initializeApp(firebaseConfig);
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6Le95uYkAAAAAFxj84IL15GI_m-WEQ-JMNtAc8vJ'),
    isTokenAutoRefreshEnabled: true
  });
  const db = getFirestore(app);
  const firebaseFunctions = getFunctions();
  const auth = getAuth(app);
  const googleProvider = new GoogleAuthProvider();

  const [user, setUser] = useState(null);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });

    // Set UTM user params for Amplitude
    const utmParams = getUTMQueryParams(document.location.search);
    const identifyObj = new amplitude.Identify();
    for (const param in utmParams) {
      identifyObj.setOnce("utm_" + param, utmParams[param]);
    }
    if(Object.keys(utmParams).length !== 0){
      amplitude.identify(identifyObj);
    }
  }, []);

  const handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = window.innerHeight;
    const scrolled = winScroll / height;
    document.getElementById("nav").style.backgroundColor = 'rgba(0,0,0,' + Math.max(0, scrolled * 1.5) + ')';
  };

  const handleResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useEffect(() => {
    amplitude.init('e2dd5896a3347975d7e8fed6f48dda07');
  }, []);

  const [waitlistModalVisible, setWaitlistModalVisible] = useState(false);
  const [requestModalVisible, setRequestModalVisible] = useState(false);
  const [destinationInput, setDestinationInput] = useState('');
  const [waitlistSubmitted, setWaitlistSubmitted] = useState(false);
  const [destinationSubmitted, setDestinationSubmitted] = useState(false);
  const [waitlistName, setWaitlistName] = useState('');
  const [waitlistEmail, setWaitlistEmail] = useState('');
  const [waitlistPhone, setWaitlistPhone] = useState('');
  const [guideRequestDestination, setGuideRequestDestination] = useState('');
  const [guideRequestDates, setGuideRequestDates] = useState('');
  const [waitlistError, setWaitlistError] = useState('');
  const [currentGuideId, setCurrentGuideId] = useState(null);

  const [bookModalVisible, setBookModalVisible] = useState(false);
  const [bookInquirySubmitted, setBookInquirySubmitted] = useState(false);
  const [bookName, setBookName] = useState('');
  const [bookEmail, setBookEmail] = useState('');
  const [bookPhone, setBookPhone] = useState('');
  const [bookError, setBookError] = useState('');

  const [selectedNav, setSelectedNav] = useState('');

  const [memberModalVisible, setMemberModalVisible] = useState(false);
  const [signInModalVisible, setSignInModalVisible] = useState(false);

  const paths = location.pathname.split('/');

  return (<div>
    {
      memberModalVisible || signInModalVisible
        ? <div className="dim-view"/>
        : null
    }
    <SignInModal
      signInModalVisible={signInModalVisible}
      setSignInModalVisible={setSignInModalVisible}
      isMobile={isMobile}
      auth={auth}
      signInWithEmailAndPassword={signInWithEmailAndPassword}
      googleProvider={googleProvider}
    />
    <MembershipModal
      memberModalVisible={memberModalVisible}
      setMemberModalVisible={setMemberModalVisible}
      isMobile={isMobile}
      auth={auth}
      createUserWithEmailAndPassword={createUserWithEmailAndPassword}
      firebaseFunctions={firebaseFunctions}
      googleProvider={googleProvider}
      db={db}
      user={user}
      currentGuideId={currentGuideId}
    />
    <div id="nav" className="mynav">
      <div style={{ cursor: 'pointer' }} onClick={() => {
        navigate('/');
      }} className={`primary-header5 ${paths[1] === 'ai-guide' ? 'white' : 'blue'}`}>Reverie.</div>
      <div className="nav-right-row">
        {user === null && !isMobile &&
          <div onClick={() => setSignInModalVisible(true)} style={{ color: 'inherit', borderRadius: 5, padding: '5.5px 18px 5.5px 18px', border: `1px solid ${paths[1] === 'ai-guide' ? 'white' : '#000814'}`, display: 'inline-block', cursor: 'pointer' }}>
            <div className={`secondary-header3 ${paths[1] === 'ai-guide' ? 'white' : 'blue'}`}>Sign in</div>
          </div>
        }
        <div onClick={() => {
          if (user !== null) {
            navigate('/manage-account');
          } else {
            navigate('/request-guide');
          }
        }} className="horizontal-spacing-small blue-fill" style={{ border: '1px solid #000814', padding: '6px 24px 6px 24px', borderRadius: 5, display: 'inline-block', cursor: 'pointer' }}>
          <div style={{ WebkitTextStrokeColor: 'white' }} className="secondary-header3 white">{user !== null ? 'My profile' : 'Get Started'}</div>
        </div>
      </div>
    </div>
    <ScrollToTop/>
    <Routes>
      <Route path="/" element={<LandingPageV2
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
      />}/>
      <Route path="/privacy" element={<PrivacyPolicy />}/>
      <Route path="/experiences/:id" element={<ExperienceListingDetails
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setSignInModalVisible={(visible) => setSignInModalVisible(visible)}
      />}/>
      <Route path="/stays/:id" element={<StayListingDetails
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setSignInModalVisible={(visible) => setSignInModalVisible(visible)}
      />}/>
      <Route path="/ai-guide/:id" element={<AIGuidePage
        setMemberModalVisible={(visible) => setMemberModalVisible(visible)}
        setSignInModalVisible={(visible) => setSignInModalVisible(visible)}
        setGuideId={(guide_id) => setCurrentGuideId(guide_id)}
      />}/>
      <Route path="/about" element={<AboutPage />}/>
      <Route path="/request-guide" element={<GuideRequestPage />}/>
      <Route path="/manage-account" element={<ManageAccountScreen />} />
    </Routes>
    <div className="offwhite-fill" style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}>
      <div className="footer-container">
        <div className="footer-column">
          <div onClick={() => navigate('/')} style={{ marginTop: -6 }} className="primary-header5 blue">Reverie.</div>
          <div className="body3 blue vertical-spacing-small">2023 All Rights Reserved</div>
        </div>
        <div className="footer-column">
          <div className="primary-header7 blue">Company</div>
          <Link onClick={() =>
            amplitude.track('privacy_policy_clicked', {
              surface: 'footer',
            })} className="body3 blue vertical-spacing-small" to="privacy">Privacy Policy</Link>
        </div>
        <div className="footer-column">
          <div className="primary-header7 blue">Contact</div>
          <a className="body3 blue vertical-spacing-small" href="mailto:eric@travelreverie.com">Email</a>
        </div>
      </div>
    </div>
  </div>);
}

const SignInModal = (props) => {
  const {
    signInModalVisible,
    setSignInModalVisible,
    isMobile,
    auth,
    signInWithEmailAndPassword,
    googleProvider,
  } = props;

  const [emailInput, setEmailInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [error, setError] = useState('');

  const signInWithGoogle = () => {
    amplitude.track('attempt_sign_in', {
      method: 'google',
    });
    signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      const user = result.user;
      setSignInModalVisible(false);
      amplitude.track('sign_in_success', {
        name: user.displayName,
        email: user.email,
        uid: user.uid,
        method: 'google',
      });
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorMessage);
      amplitude.track('sign_in_error', {
        error_code: errorCode,
        error_message: errorMessage,
        method: 'google',
      });
    });
  }

  const signIn = () => {
    amplitude.track('attempt_sign_in', {
      email: emailInput,
      method: 'email_password',
    });
    if (emailInput === '') {
      setError('Please enter your email.');
      return;
    }
    if (passInput === '') {
      setError('Please enter your password.');
      return;
    }
    signInWithEmailAndPassword(auth, emailInput, passInput)
      .then((userCredential) => {
        setSignInModalVisible(false);
        const user = userCredential.user;
        amplitude.track('sign_in_success', {
          email: emailInput,
          uid: user.uid,
          method: 'email_password',
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        amplitude.track('sign_in_error', {
          email: emailInput,
          error_code: errorCode,
          error_message: errorMessage,
          method: 'email_password',
        });
      });
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : 'auto' }} className={'white-fill notify-modal' + (
        signInModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: 'black',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setSignInModalVisible(false);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      <div style={{ width: isMobile ? '100%' : 450 }}>
        <div style={{ alignSelf: 'center', textAlign: 'center' }} className="primary-header3 blue">Sign In</div>
        <div onClick={() => signInWithGoogle()} className="vertical-spacing-medium lightergray-fill" style={{ cursor: 'pointer', boxSizing: 'border-box', width: '100%', padding: '8px 12px 8px 12px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <img style={{ width: 20, height: 20 }} src={require('./images/google_logo.png')} />
          <div className="body3 bold blue">Continue with Google</div>
          <div style={{ width: 20, height: 20 }} />
        </div>
        <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
          <div style={{ color: 'gray' }} className="secondary-header3">Or</div>
          <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
        </div>
        <div className="secondary-header3 blue vertical-spacing-medium">Email</div>
        <input
          className="vertical-spacing-small notify-input body3 blue"
          type="text"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
        />
        <div className="secondary-header3 blue vertical-spacing-medium">Password</div>
        <input
          className="vertical-spacing-small notify-input body3 blue"
          type="password"
          value={passInput}
          onChange={(event) => setPassInput(event.target.value)}
        />
        <div onClick={() => signIn()} style={{ cursor: 'pointer', padding: 8, boxSizing: 'border-box', textAlign: 'center', width: '100%' }} className="blue-fill vertical-spacing-large">
          <div className="body3 white">Sign in</div>
        </div>
        {error !== '' && <div className="vertical-spacing-small body3 blue">{error}</div>}
      </div>
    </div>
  )
}

const MembershipModal = (props) => {
  const {
    currentGuideId,
    memberModalVisible,
    setMemberModalVisible,
    isMobile,
    auth,
    createUserWithEmailAndPassword,
    firebaseFunctions,
    googleProvider,
    db,
    user,
  } = props;

  const [selectedPlan, setSelectedPlan] = useState('yearly');

  const [showAccountCreation, setShowAccountCreation] = useState(false);
  const [nameInput, setNameInput] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [passInput, setPassInput] = useState('');
  const [error, setError] = useState('');
  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const createAccountWithGoogle = () => {
    amplitude.track('attempt_create_account', {
      checkout_loading: checkoutLoading,
      method: 'google',
    });
    if (checkoutLoading) {
      return;
    }
    setCheckoutLoading(true);
    signInWithPopup(auth, googleProvider)
    .then(async (result) => {
      const user = result.user;
      setDoc(doc(db, 'users', user.uid), {
        name: user.displayName,
        email: user.email,
        created_timestamp: moment().format(),
        uid: user.uid,
        platform: 'ai',
      });
      amplitude.track('create_account_success', {
        name: user.displayName,
        email: user.email,
        uid: user.uid,
        method: 'google',
      });
      const createStripeCheckout = httpsCallable(firebaseFunctions, 'createstripecheckout');
      const stripe_session = await createStripeCheckout({
        price_id: selectedPlan === 'yearly' ? 'price_1NTHw0DsOC4h1ZjODzxnKpHL' : 'price_1NTHw0DsOC4h1ZjOqql3cTig',
        uid: user.uid,
        customer_email: user.email,
        success_url: `https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${currentGuideId}`,
        cancel_url: `https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${currentGuideId}`,
      });
      setCheckoutLoading(false);
      window.open(stripe_session.data.session_url, "_self");
    }).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      amplitude.track('create_account_error', {
        error_code: errorCode,
        error_message: errorMessage,
        method: 'google',
      });
    });
  }

  const createAccount = () => {
    amplitude.track('attempt_create_account', {
      name: nameInput,
      email: emailInput,
      checkout_loading: checkoutLoading,
      method: 'email_password',
    });
    if (checkoutLoading) {
      return;
    }
    if (nameInput === '') {
      amplitude.track('create_account_error', {
        name: nameInput,
        email: emailInput,
        message: 'Please enter your name.',
      });
      setError('Please enter your name.');
      return;
    }
    if (emailInput === '') {
      amplitude.track('create_account_error', {
        name: nameInput,
        email: emailInput,
        message: 'Please enter your email.',
      });
      setError('Please enter your email.');
      return;
    }
    if (passInput === '') {
      amplitude.track('create_account_error', {
        name: nameInput,
        email: emailInput,
        message: 'Please enter your password.',
      });
      setError('Please enter a password.');
      return;
    }
    setCheckoutLoading(true);
    createUserWithEmailAndPassword(auth, emailInput, passInput)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        updateProfile(user, {
          displayName: nameInput
        })
        setDoc(doc(db, 'users', user.uid), {
          name: nameInput,
          email: emailInput,
          created_timestamp: moment().format(),
          uid: user.uid,
          platform: 'ai',
        });
        amplitude.track('create_account_success', {
          name: nameInput,
          email: emailInput,
          uid: user.uid,
          method: 'email_password',
        });
        const createStripeCheckout = httpsCallable(firebaseFunctions, 'createstripecheckout');
        const stripe_session = await createStripeCheckout({
          price_id: selectedPlan === 'yearly' ? 'price_1NTHw0DsOC4h1ZjODzxnKpHL' : 'price_1NTHw0DsOC4h1ZjOqql3cTig',
          uid: user.uid,
          customer_email: emailInput,
          success_url: `https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${currentGuideId}`,
          cancel_url: `https://${window.location.hostname.match(/[a-zA-Z0-9-]*\.[a-zA-Z0-9-]*$/)[0]}/ai-guide/${currentGuideId}`,
        });
        setCheckoutLoading(false);
        window.open(stripe_session.data.session_url, "_self");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorMessage);
        amplitude.track('create_account_error', {
          name: nameInput,
          email: emailInput,
          error_code: errorCode,
          error_message: errorMessage,
          method: 'email_password',
        });
      });
  }

  return (
    <div style={{ transition: 'all 0.8s ease', display: 'flex', flexDirection: 'column', borderRadius: 5, alignItems: 'flex-start', textAlign: 'left', width: isMobile ? '95vw' : 'auto' }} className={'white-fill notify-modal' + (
        memberModalVisible
        ? ' slide-up'
        : ' slide-out')}>
      <IconContext.Provider value={{
          size: 36,
          color: 'black',
          className: 'x-icon'
        }}>
        <div onClick={() => {
          setMemberModalVisible(false);
          setTimeout(() => {
            setSelectedPlan('yearly');
            setShowAccountCreation(false);
          }, 1000);
        }}>
          {Icons.x}
        </div>
      </IconContext.Provider>
      {showAccountCreation ?
        <div style={{ width: isMobile ? '100%' : 500 }}>
          <div style={{ width: isMobile ? '70%' : '100%' }} className="primary-header3 blue">Let's create your account.</div>
          <div onClick={() => createAccountWithGoogle()} className="vertical-spacing-medium lightergray-fill" style={{ cursor: 'pointer', boxSizing: 'border-box', width: '100%', padding: '8px 12px 8px 12px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <img style={{ width: 20, height: 20 }} src={require('./images/google_logo.png')} />
            <div className="body3 bold blue">Continue with Google</div>
            <div style={{ width: 20, height: 20 }} />
          </div>
          <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
            <div style={{ color: 'gray' }} className="secondary-header3">Or</div>
            <div style={{ height: 1, width: '45%', backgroundColor: 'gray' }} />
          </div>
          <div className="secondary-header3 blue vertical-spacing-medium">Your Name</div>
          <input
            className="vertical-spacing-small notify-input body3 blue"
            type="text"
            value={nameInput}
            onChange={(event) => setNameInput(event.target.value)}
          />
          <div className="secondary-header3 blue vertical-spacing-medium">Email</div>
          <input
            className="vertical-spacing-small notify-input body3 blue"
            type="text"
            value={emailInput}
            onChange={(event) => setEmailInput(event.target.value)}
          />
          <div className="secondary-header3 blue vertical-spacing-medium">Password</div>
          <input
            className="vertical-spacing-small notify-input body3 blue"
            type="password"
            value={passInput}
            onChange={(event) => setPassInput(event.target.value)}
          />
          <div onClick={() => createAccount()} style={{ cursor: 'pointer', padding: 8, boxSizing: 'border-box', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: '100%' }} className="blue-fill vertical-spacing-large">
            {checkoutLoading ?
              <TailSpin
                height="20"
                width="20"
                color="white"
                radius="1"
                visible={true}
              />
              :
              <div className="body3 white">Create account & proceed to payment</div>
            }
          </div>
          {error !== '' && <div className="vertical-spacing-small body3 blue">{error}</div>}
        </div>
        :
        <div style={{ width: isMobile ? '100%' : 500, display: 'flex', flexDirection: 'column' }}>
          <div style={{ alignSelf: 'center', textAlign: 'center' }} className="primary-header4 blue">Subscribe to Reverie AI</div>
          <div className="vertical-spacing-small" style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center' }}>
            <div style={{ cursor: 'pointer' }} onClick={() => setSelectedPlan('yearly')}>
              <div style={{ fontFamily: selectedPlan === 'yearly' ? 'Jost-Regular' : 'Jost-Light' }} className="secondary-header3 blue">Yearly</div>
              {selectedPlan === 'yearly' && <div className="blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '100%', float: 'right' }} />}
            </div>
            <div style={{ cursor: 'pointer' }} onClick={() => setSelectedPlan('monthly')} className="horizontal-spacing-large">
              <div style={{ fontFamily: selectedPlan === 'monthly' ? 'Jost-Regular' : 'Jost-Light' }} className="secondary-header3 blue">Monthly</div>
              {selectedPlan === 'monthly' && <div className="blue-fill vertical-spacing-xsmall" style={{ height: 1, width: '100%', float: 'left' }} />}
            </div>
          </div>
          <div className="vertical-spacing-large" style={{ alignSelf: 'center', display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
            <div style={{ fontSize: 48, lineHeight: '0.8em' }} className="body1 blue">{selectedPlan === 'yearly' ? '$20' : '$3'}</div>
            <div className="horizontal-spacing-xsmall body3 blue">{`paid ${selectedPlan}`}</div>
          </div>
          <div className="vertical-spacing-xlarge" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 20,
                color: '#000814',
              }}>
              <div>
                {Icons.plane}
              </div>
            </IconContext.Provider>
            <div className="horizontal-spacing-medium">
              <div className="body2 bold blue">Create unlimited AI travel guides</div>
              <div className="body3 blue">Plan your dream trip in minutes by letting our travel AI do the research for you.</div>
            </div>
          </div>
          <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 20,
                color: '#000814',
              }}>
              <div>
                {Icons.map}
              </div>
            </IconContext.Provider>
            <div className="horizontal-spacing-medium">
              <div className="body2 bold blue">Discover your full guide of recommended stays & experiences</div>
              <div className="body3 blue">Explore dozens of recommendations that are uniquely tailored to your travel needs & preferences.</div>
            </div>
          </div>
          <div className="vertical-spacing-medium" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconContext.Provider value={{
                size: 20,
                color: '#000814',
              }}>
              <div>
                {Icons.people}
              </div>
            </IconContext.Provider>
            <div className="horizontal-spacing-medium">
              <div className="body2 bold blue">Get unlimited access to Ask Reverie AI</div>
              <div className="body3 blue">Receive instant answers to all your travel questions so that you can book with confidence.</div>
            </div>
          </div>
          <div onClick={async () => {
            if (user !== null && user.stripe_subscription_status !== 'active') {
              const createStripeCheckout = httpsCallable(firebaseFunctions, 'createstripecheckout');
              const stripe_session = await createStripeCheckout({
                price_id: selectedPlan === 'yearly' ? 'price_1NT7UhDsOC4h1ZjOanq9xsVD' : 'price_1NT7UhDsOC4h1ZjOxXCGrFVF',
                uid: user.uid,
                customer_email: user.email,
              });
              setCheckoutLoading(false);
              window.open(stripe_session.data.session_url, "_self");
            } else {
              setShowAccountCreation(true);
            }
          }} style={{ cursor: 'pointer', padding: 8, boxSizing: 'border-box', textAlign: 'center', width: '100%' }} className="blue-fill vertical-spacing-large">
            <div className="body3 white">{`Join with ${selectedPlan} membership`}</div>
          </div>
        </div>
      }
    </div>
  )
}

export default App;
