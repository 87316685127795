import { initializeApp } from "firebase/app";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
} from "firebase/firestore";

export const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

export function getUTMQueryParams(qs) {
  qs = qs.split("+").join(" ");

  var params = {}, tokens,
      re = /\butm_([^=]+)=([^&]+)/g;

  while (tokens = re.exec(qs)) {
      params[decodeURIComponent(tokens[1])]
          = decodeURIComponent(tokens[2]);
  }
  return params;
}

export function pluralize(word, count) {
  return count > 1 ? (word + 's') : word;
}

export function bedsToString(beds) {
  let result = '';
  for (var bedType in beds) {
    result += beds[bedType] + ' ' + pluralize(bedType, beds[bedType]) + ', ';
  }
  return result.slice(0, result.length - 2);
}

export function capFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function fetchCollection(collectionName, callback) {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const results = [];
  const collectionQuery = query(collection(db, collectionName), where("incomplete", "!=", true));
  const snapshot = await getDocs(collectionQuery);
  snapshot.forEach((doc) => {
    const listing = doc.data();
    results.push({
      id: doc.id,
      collection: collectionName,
      ...listing
    })
  });
  callback(results);
}
