import '../App.css';
import '../styles/LandingPage.css';
import {Fade, Reveal} from "react-awesome-reveal";
import React, {useState, useRef, useEffect} from 'react';
import axios from "axios";
import {useMediaQuery} from 'react-responsive';
import * as amplitude from '@amplitude/analytics-browser';
import {useNavigate} from 'react-router-dom';
import {initializeApp} from "firebase/app";
import {
  getFirestore,
  query,
  where,
  getDocs,
  collection,
  doc,
  setDoc,
  addDoc,
  getDoc,
} from "firebase/firestore";
import {
  getAuth,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import {IconContext} from "react-icons";
import Icons from '../utils/Icons.js';
import {Collapse} from 'react-collapse';

const firebaseConfig = {
  apiKey: "AIzaSyBJgZPTycZttDE2RBoXq-JMP9xQXLqT8jU",
  authDomain: "reverie-5b042.firebaseapp.com",
  projectId: "reverie-5b042",
  storageBucket: "reverie-5b042.appspot.com",
  messagingSenderId: "891469819114",
  appId: "1:891469819114:web:d1c82e97a12017e5cffb93",
  measurementId: "G-6WRG4QGY2G"
};

const Destinations = [
  {
    name: 'Thailand',
    image: require('../images/thailand_guide.jpg'),
  },
  {
    name: 'Iceland',
    image: require('../images/iceland_guide.jpg'),
  },
  {
    name: 'Japan',
    image: require('../images/japan_guide.png'),
  },
];

const Rankings = [
  'jaiyen',
  'canyoneer_bali',
  'tabitabi',
  'zengarden',
  'emerald',
  'atv_kohsamui',
  'rayavadee',
  'oasis',
  'pavillions',
  'kayak_krabi',
  'raijin',
];

const FeaturedDetails = {
  jaiyen: {
    height: '15vw',
    mobile_width: '70vw',
  },
  canyoneer_bali: {
    height: '24vw',
    mobile_width: '65vw',
  },
  tabitabi: {
    height: '20vw',
    mobile_width: '60vw',
  },
  zengarden: {
    height: '26vw',
    mobile_width: '80vw',
  },
  emerald: {
    height: '18vw',
    mobile_width: '65vw',
  },
  atv_kohsamui: {
    height: '15vw',
    mobile_width: '85vw',
  },
  rayavadee: {
    height: '21vw',
    mobile_width: '75vw',
  },
  oasis: {
    height: '23vw',
    mobile_width: '80vw',
  },
  pavillions: {
    height: '16vw',
    mobile_width: '50vw',
  },
  kayak_krabi: {
    height: '22vw',
    mobile_width: '70vw',
  },
  raijin: {
    height: '14vw',
    mobile_width: '70vw',
  },
}

const ExampleGuides = [
  {
    country: 'Thailand',
    image: require('../images/thailand_guide.jpg'),
    guideId: 'timon-in-thailand-1685133991152',
  },
  {
    country: 'France',
    image: require('../images/france_guide.jpg'),
    guideId: 'cindy-in-france-1684947178455',
  },
  {
    country: 'Iceland',
    image: require('../images/iceland_guide.jpg'),
    guideId: 'michelle-in-iceland-1685737498044',
  },
];

function LandingPageV2(props) {
  const [user, setUser] = useState(null);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  const auth = getAuth(app);
  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists()) {
          setUser(userSnap.data());
        } else {
          console.log("User document doesn't exist.");
        }
      } else {
        setUser(null);
      }
    });
  },[]);

  useEffect(() => {
    amplitude.track('view_landing_page');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          amplitude.track('view_' + entry.target.id, {
            surface: 'landing_page',
          });
        }
      });
    }, {
      threshold: 0.5,
    });

    document.querySelectorAll('.observe').forEach((i) => {
      observer.observe(i);
    });
  }, []);

  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [featuredListings, setFeaturedListings] = useState([]);
  const navigate = useNavigate();

  const fetchFeaturedListings = async () => {
    const featured = [];

    const staysQuery = query(collection(db, 'stays'));
    const staysSnapshot = await getDocs(staysQuery);
    staysSnapshot.forEach((doc) => {
      const staysObj = doc.data();
      featured.push({
        id: doc.id,
        type: 'stays',
        ...staysObj
      })
    });

    const experiencesQuery = query(collection(db, 'experiences'));
    const expSnapshot = await getDocs(experiencesQuery);
    expSnapshot.forEach((doc) => {
      const experienceObj = doc.data();
      featured.push({
        id: doc.id,
        type: 'experiences',
        ...experienceObj
      })
    });

    setFeaturedListings(featured);
  }

  useEffect(() => {
    fetchFeaturedListings();
  }, []);

  const featuredToShow = featuredListings.filter(listing => Rankings.includes(listing.id));
  featuredToShow.sort((a, b) => Rankings.indexOf(a.id) - Rankings.indexOf(b.id));

  const isMember = user !== null && user.stripe_subscription_status === 'active';

  const handleCTA = () => {
    navigate('/request-guide');
  }

  const cta = isMember ? 'Create a guide' : 'Create your guide';

  return (
    <div style={{ width: '100vw', paddingTop: 96, display: 'flex', flexDirection: 'column', overflowX: 'hidden' }} className="white-fill">
      <HeroSection handleCTA={() => handleCTA()} cta={cta}/>
      <AffirmationsSection />
      <ExampleGuidesSection handleCTA={() => handleCTA()} cta={cta}/>
      <TestimonialSection handleCTA={() => handleCTA()} cta={cta}/>
      <WhySection handleCTA={() => handleCTA()} cta={cta}/>
      <CurationSection listings={featuredToShow} handleCTA={() => handleCTA()} cta={cta}/>
      <FAQSection />
    </div>
  );
}

const HeroSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const {
    handleCTA,
    cta
  } = props;

  return (
    <div id="hero_section" className="observe" style={{ marginTop: 48, display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', width: isMobile ? '90%' : '80%', alignSelf: 'center' }}>
      <Fade triggerOnce={true} damping={0.2} cascade direction="up">
        <div style={{ lineHeight: '1em', letterSpacing: '-0.04em' }} className="primary-header1 blue">The most effortless way to book travel.</div>
        <div className="vertical-spacing-xsmall secondary-header1 blue">{isMobile ? 'Powered by GPT-AI' : 'Powered by GPT-AI and travel experts'}</div>
        <div className="vertical-spacing-medium body1 blue" style={{ width: '100%', alignSelf: 'center', textAlign: 'center' }}>Book your dream trip in 10% of the time by letting Reverie do the research for you.</div>
        <div onClick={() => {
          amplitude.track('click_start_button', {
            surface: 'landing_page',
            section: 'hero_section'
          });
          handleCTA();
        }} className=" vertical-spacing-xlarge blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
          <div className="secondary-header3 white">Get Started</div>
        </div>
      </Fade>
      {isMobile ?
        <img className="vertical-spacing-large" style={{ width: '95%', objectFit: 'contain' }} src={require('../images/iphone.png')} />
        :
        <img className="vertical-spacing-large" style={{ width: '90%', objectFit: 'contain' }} src={require('../images/macbook.png')} />
      }
    </div>
  );
}

const AffirmationsSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  return (
    <div style={{ marginTop: 64, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '110%', alignSelf: 'center' }}>
      <div className="blue-fill" style={{ height: 1, width: '100%'}} />
      <div className="vertical-spacing-small primary-header6 blue" style={{ wordWrap: 'break-word', textOverflow: '', overflow: 'hidden', maxHeight: '4em', lineHeight: '1em', whiteSpace: isMobile ? 'wrap' : 'nowrap' }}>Say no to boring trips. Say no to rude hosts. Say no to tourist traps. Say no to overpriced hotels. Say no to inauthentic experiences. Say no to chain restaurants. Say no to boring trips. Say no to rude hosts. Say no to tourist traps. Say no to overpriced hotels. Say no to inauthentic experiences. Say no to chain restaurants.</div>
      <div className="blue-fill vertical-spacing-small" style={{ height: 1, width: '100%'}} />
    </div>
  );
}

const CurationSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const navigate = useNavigate();
  const {
    handleCTA,
    cta,
  } = props;

  return (
    <div id="example_listings_section" className="observe" style={{ paddingTop: 64, paddingBottom: isMobile ? 24 : 64, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignSelf: 'center' }}>
      <div className="primary-header3 blue" style={{ textAlign: 'center', width: isMobile ? '80%' : '100%', alignSelf: 'center' }}>It's time for trips that live up to the photos.</div>
      <div className="body2 blue vertical-spacing-small" style={{ textAlign: 'center', width: isMobile ? '80%' : '100%', lineHeight: '1.7em' }}>Discover what our members have booked through their Reverie AI travel guides.</div>
      <div onClick={() => {
        handleCTA();
        amplitude.track('click_start_button', {
          surface: 'landing_page',
          section: 'example_listings_section'
        });
      }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
        <div className="secondary-header3 white">Get Started</div>
      </div>
      <div className="curated-collage-row vertical-spacing-large" style={{ marginLeft: 0, position: 'relative', WebkitOverflowScrolling: 'touch', display: 'flex', flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'hidden', boxSizing: 'border-box', width: '100vw', height: isMobile ? '50vh' : '30vw', alignItems: 'center' }}>
        {props.listings.map((listing, i) =>
          <div onClick={() => {
            amplitude.track('click_example_listing', {
              surface: 'landing_page',
              listing_id: listing.id,
            });
            navigate(`/${listing.type}/${listing.id}`);
          }} style={{ cursor: 'pointer', flex: '0 0 auto', position: 'relative', marginRight: 24 }}>
            <img style={{ width: isMobile ? FeaturedDetails[listing.id].mobile_width : '100%', height: isMobile ? 'auto' : FeaturedDetails[listing.id].height, objectFit: 'contain' }} src={listing.cover_image_url} />
            <div className="primary-header6 blue vertical-spacing-xsmall">{listing.name}</div>
            <div className="secondary-header4 blue">{listing.country}</div>
          </div>
        )}
      </div>
    </div>
  );
}

const TestimonialSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const {
    handleCTA,
    cta
  } = props;

  return (isMobile ?
    <div id="testimonial_section" className="observe" style={{ paddingTop: 64, paddingBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', alignSelf: 'center' }}>
      <div style={{ width: '85%', textAlign: 'right', alignSelf: 'center', alignItems: 'flex-end' }}>
        <Fade triggerOnce={true} damping={0.2} cascade direction="up">
          <div className="primary-header3 blue">Tailored to the way you travel.</div>
          <div className="body2 blue vertical-spacing-medium">Say goodbye to boring travel sites that give the same results for everyone. Say hello to recommendations that are uniquely you.</div>
          <div onClick={() => {
            handleCTA();
            amplitude.track('click_start_button', {
              surface: 'landing_page',
              section: 'testimonial_section'
            });
          }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
            <div className="secondary-header3 white">{cta}</div>
          </div>
        </Fade>
      </div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 300, width: '45%', objectFit: 'cover' }} src={require('../images/christine.png')} />
        <div style={{ marginLeft: 24, width: '45%', textAlign: 'left' }}>
          <Fade triggerOnce={true} damping={0.2} cascade direction="right">
            <div className="vertical-spacing-small primary-header4 blue">Christine.</div>
            <div className="body4 blue vertical-spacing-medium" style={{ width: '100%' }}>"Reverie's travel AI has been a <span className="bold">lifesaver for me</span> - I feel like it's <span className="bold">taken away all the stress</span> from trip planning."</div>
          </Fade>
        </div>
      </div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 300, width: '45%', objectFit: 'cover' }} src={require('../images/mel.png')} />
        <div style={{ marginLeft: 24, width: '45%', textAlign: 'left' }}>
          <Fade triggerOnce={true} damping={0.2} cascade direction="right">
            <div className="vertical-spacing-small primary-header4 blue">Melissa.</div>
            <div className="body4 blue vertical-spacing-medium" style={{ width: '100%' }}>"Planning my trips with Reverie is <span className="bold">super easy and fun</span>!! It’s so much faster than scrolling for hours on TikTok, Airbnb, and Google to find stuff I like."</div>
          </Fade>
        </div>
      </div>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <img style={{ height: 300, width: '45%', objectFit: 'cover' }} src={require('../images/avi.jpg')} />
        <div style={{ marginLeft: 24, width: '45%', textAlign: 'left' }}>
          <Fade triggerOnce={true} damping={0.2} cascade direction="right">
            <div className="vertical-spacing-small primary-header4 blue">Avi.</div>
            <div className="body4 blue vertical-spacing-medium" style={{ width: '100%' }}>"I can’t imagine booking another trip without Reverie - they understand <span className="bold">what I'm looking for</span> perfectly."</div>
          </Fade>
        </div>
      </div>
    </div>
    :
    <div id="testimonial_section" className="observe" style={{ paddingTop: 64, paddingBottom: 64, display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between', width: '68%', alignSelf: 'center' }}>
      <div style={{ width: '43%' }}>
        <img style={{ height: '69vh', width: '100%', objectFit: 'cover' }} src={require('../images/christine.png')} />
        <Fade triggerOnce={true} damping={0.2} cascade direction="up">
          <div className="primary-header6 blue">Christine.</div>
          <div className="body3 blue vertical-spacing-xsmall horizontal-spacing-medium" style={{ width: '80%' }}>"Reverie's travel AI has been a <span className="bold">lifesaver for me</span> - I feel like it's <span className="bold">taken away all the stress</span> from trip planning."</div>
        </Fade>
      </div>
      <div className="vertical-spacing-medium" style={{ width: '50%', textAlign: 'right' }}>
        <Fade triggerOnce={true} damping={0.2} cascade direction="up">
          <div className="primary-header3 blue">Tailored to the way you travel.</div>
          <div className="body2 blue vertical-spacing-medium">Say goodbye to boring travel sites that give the same results for everyone. Say hello to recommendations that are uniquely you.</div>
          <div onClick={() => {
            handleCTA();
            amplitude.track('click_start_button', {
              surface: 'landing_page',
              section: 'testimonial_section'
            });
          }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
            <div className="secondary-header3 white">{cta}</div>
          </div>
        </Fade>
        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: '50%', textAlign: 'left', marginTop: -24 }}>
            <img style={{ height: 380, width: '100%', objectFit: 'cover' }} src={require('../images/mel.png')} />
            <Fade triggerOnce={true} damping={0.2} cascade direction="up">
              <div className="primary-header6 blue">Melissa.</div>
              <div className="body4 blue vertical-spacing-xsmall horizontal-spacing-medium" style={{ width: '80%' }}>"Planning my trips with Reverie is <span className="bold">super easy and fun</span>!! It’s so much faster than scrolling for hours on TikTok, Airbnb, and Google to find stuff I like."</div>
            </Fade>
          </div>
          <div style={{ width: '40%', textAlign: 'left', marginTop: 96 }}>
            <img style={{ height: 300, width: '100%', objectFit: 'cover' }} src={require('../images/avi.png')} />
            <Fade triggerOnce={true} damping={0.2} cascade direction="up">
              <div className="primary-header6 blue">Avi.</div>
              <div className="body4 blue vertical-spacing-xsmall horizontal-spacing-medium" style={{ width: '80%' }}>"I can’t imagine booking another trip without Reverie - they understand <span className="bold">what I'm looking for</span> perfectly."</div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
}

const WhySection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const {
    handleCTA,
    cta
  } = props;

  return (
    <div id="value_prop_section" className="observe" style={{ paddingTop: 64, paddingBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: isMobile ? '85%' : '100%', alignSelf: 'center' }}>
      <Fade triggerOnce={true} direction="up">
        <div style={{ marginLeft: isMobile ? 0 : 156 }} className="primary-header3 blue">Meet the new way to book travel.</div>
      </Fade>
      <div style={{ marginTop: 48, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'space-between', width: '100%' }}>
        <div style={{ marginTop: isMobile ? 24 : 0, marginLeft: isMobile ? 0 : 156, width: isMobile ? '100%' : '40%' }}>
          <Fade triggerOnce={true} damping={0.2} cascade direction="up">
            <div style={{ fontSize: 48, lineHeight: '1em' }} className="body1 blue">01</div>
            <div className="primary-header5 blue vertical-spacing-small">Discover the perfect trip for you.</div>
            <div style={{ width: isMobile ? '100%' : '80%' }} className="body2 blue vertical-spacing-medium">Instantly find the stays & experiences that best match your location, budget, and other travel preferences.</div>
            <div onClick={() => {
              handleCTA();
              amplitude.track('click_start_button', {
                surface: 'landing_page',
                section: 'value_prop_section'
              });
            }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
              <div className="secondary-header3 white">{cta}</div>
            </div>
          </Fade>
        </div>
        <img style={{ width: isMobile ? '100%' : '50%', height: isMobile ? 'auto' : '33vw' }} src={require('../images/collage1.png')} />
      </div>
      <div style={{ marginTop: isMobile ? 84 : 124, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row-reverse', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'space-between', width: '100%' }}>
        <div style={{ marginTop: isMobile ? 24 : 0, width: isMobile ? '100%' : '40%' }}>
          <Fade triggerOnce={true} damping={0.2} cascade direction="up">
            <div style={{ fontSize: 48, lineHeight: '1em' }} className="body1 blue">02</div>
            <div className="primary-header5 blue vertical-spacing-small">Plan your trip in minutes, not hours.</div>
            <div style={{ width: isMobile ? '100%' : '80%' }} className="body2 blue vertical-spacing-medium">No more doom-scrolling for hours on social media and travel sites - let Reverie do the research for you.</div>
            <div onClick={() => {
              handleCTA();
              amplitude.track('click_start_button', {
                surface: 'landing_page',
                section: 'value_prop_section'
              });
            }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
              <div className="secondary-header3 white">{cta}</div>
            </div>
          </Fade>
        </div>
        <img style={{ width: isMobile ? '100%' : '50%', height: isMobile ? 'auto' : '33vw' }} src={require('../images/collage2.png')} />
      </div>
      <div style={{ marginTop: isMobile ? 84 : 124, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ marginTop: isMobile ? 24 : 0, marginLeft: isMobile ? 0 : 156, width: isMobile ? '100%' : '40%' }}>
          <Fade triggerOnce={true} damping={0.2} cascade direction="up">
            <div style={{ fontSize: 48, lineHeight: '1em' }} className="body1 blue">03</div>
            <div className="primary-header5 blue vertical-spacing-small">Insider knowledge - without the research.</div>
            <div style={{ width: isMobile ? '100%' : '80%' }} className="body2 blue vertical-spacing-medium">Discover tips that'll make you feel like a travel expert - from how to get a more local experience to the best times to avoid the crowds, we've researched it all.</div>
            <div onClick={() => {
              handleCTA();
              amplitude.track('click_start_button', {
                surface: 'landing_page',
                section: 'value_prop_section'
              });
            }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
              <div className="secondary-header3 white">{cta}</div>
            </div>
          </Fade>
        </div>
        <img style={{ width: isMobile ? '100%' : '50%', height: isMobile ? 'auto' : '33vw' }} src={require('../images/collage3.png')} />
      </div>
    </div>
  );
}

const ExampleGuidesSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});
  const navigate = useNavigate();
  const {
    cta,
    handleCTA
  } = props;
  return (
    <div id="example_guides_section" className="observe" style={{ paddingTop: 64, paddingBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center', width: isMobile ? '80%' : '70%', alignSelf: 'center' }}>
      <Fade triggerOnce={true} damping={0.2} cascade direction="up">
        <div className="secondary-header3 blue">For the modern traveler</div>
        <div style={{ textAlign: 'center' }} className="primary-header3 vertical-spacing-xsmall blue">AI-powered travel guides, made just for you.</div>
      </Fade>
      <div className="vertical-spacing-large" style={{ width: '100%', display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: isMobile ? 'flex-start' : 'space-between'}}>
        <Fade triggerOnce={true} cascade>
          {ExampleGuides.map((guide, i) =>
            <div onClick={() => {
              amplitude.track('click_example_guide', {
                surface: 'landing_page',
                guide_id: guide.guideId,
              });
              navigate(`/ai-guide/${guide.guideId}`);
            }} key={i} style={{ marginBottom: isMobile ? 36 : 0, cursor: 'pointer', width: isMobile ? '100%' : '90%', border: '1px solid #000814', padding: '4px 12px 6px 12px', boxSizing: 'border-box' }}>
              <div style={{ WebkitTextStrokeWidth: 0, position: 'relative', zIndex: 2 }} className="primary-header5">{`${guide.country}.`}</div>
              <img style={{ zIndex: 1, marginTop: isMobile ? -14 : -18, height: 400, width: '100%', objectFit: 'cover' }} src={guide.image} />
            </div>
          )}
        </Fade>
      </div>
      <Fade triggerOnce={true} direction="up">
        <div onClick={() => {
          handleCTA();
          amplitude.track('click_start_button', {
            surface: 'landing_page',
            section: 'example_guides_section'
          });
        }} className=" vertical-spacing-large blue-fill" style={{ borderRadius: 5, padding: '8px 24px 8px 24px', border: '1px solid #000814', display: 'inline-block', cursor: 'pointer' }}>
          <div className="secondary-header3 white">Create Your Guide</div>
        </div>
      </Fade>
    </div>
  );
}

const FAQSection = (props) => {
  const isMobile = useMediaQuery({query: `(max-width: 760px)`});

  const [faq1Open, setfaq1Open] = useState(false);

  return (
    <div id="faq_section" className="observe" style={{ paddingTop: 64, paddingBottom: 64, display: 'flex', flexDirection: 'column', alignItems: 'center', width: isMobile ? '80%' : '50%', alignSelf: 'center' }}>
      <div className="primary-header3 blue">Frequently Asked</div>
      <div className="vertical-spacing-medium" style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <div className="body2 bold blue">How much does Reverie cost to use?</div>
        <IconContext.Provider value={{
            size: 20,
            color: '#000814'
          }}>
          <div onClick={() => setfaq1Open(!faq1Open)} style={{ marginTop: 4, cursor: 'pointer' }}>
            {faq1Open ? Icons.AiOutlineMinus : Icons.AiOutlinePlus}
          </div>
        </IconContext.Provider>
      </div>
      <Collapse isOpened={faq1Open}>
        <div style={{ width: isMobile ? '90%' : '80%' }} className="body4 blue vertical-spacing-small">Creating your first travel guide is free - you'll be able to see the first 4 recommendations and the first city. Accessing your full guide and creating more guides requires a subscription for $7/month or $60/year.</div>
      </Collapse>
    </div>
  )
}

export default LandingPageV2;
